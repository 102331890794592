export default {
  methods: {
    showError(error) {
      if (typeof(error) == 'undefined' || error == null) {
        error = 'Sorry, an error occurred.  Please try later or contact your administrator.'
      }
      this.$notify({
        title: 'Error',
        text: error,
        type: 'error',
        duration: 5500
      });
    },
    showSuccess(success) {
      this.$notify({
        title: 'Success',
        text: success,
        type: 'success',
        duration: 500
      });
    }
  }
}
