import VeeValidate from 'vee-validate'
import Notifications from 'vue-notification'
import CustomNotifications from 'mixins/custom_notifications'
import string from "../components/common/string_utilities";
import Vue from 'vue/dist/vue.esm.js';
import VueRouter from "vue-router"
import Vuex from 'vuex'
import VueSkeletonLoader from 'skeleton-loader-vue';
import Popover from 'vue-js-popover'

window.Vue = Vue
window.VueRouter = VueRouter
window.Vuex = Vuex
window.VueSkeletonLoader = VueSkeletonLoader

Vue.use(VueSkeletonLoader);
Vue.use(Vuex)
Vue.use(VueRouter);
Vue.use(string)
Vue.use(Notifications);
Vue.use(VeeValidate);
Vue.mixin(CustomNotifications);
Vue.use(Popover, { tooltip: true })
